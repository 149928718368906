@import "@src/styles/mixins.less";

.PCFooterWrap {
  background-color: #263238;
  color: #ffffff;
  font-size: 14px;
  padding: 40px;

  .PCFooterContent {
    p {
      margin: initial;
    }

    .PCColumn1ForCompany {
      .PCFooterLogo {
        width: 132px;
        height: 24px;
        margin-bottom: 20px;
      }
    }

    .PCColumn2ForMenu {
      margin-top: 16px;
      h1 {
        font-size: 18px;
      }

      .PCMenuGrid {
        display: flex;
        flex-direction: column;
        label {
          cursor: pointer;
        }
      }
    }

    .PCColumn2ForResource {
      margin-top: 16px;
      h1 {
        font-size: 18px;
      }
      .PCMenu {
        display: flex;
        flex-direction: column;
        label {
          cursor: pointer;
        }
      }
    }

    .PCColumn4ForLegal {
      margin-top: 16px;
      h1 {
        font-size: 18px;
      }

      .PCMenu {
        display: flex;
        flex-direction: column;
        label {
          cursor: pointer;
        }
      }
    }

    .PCCopyRight {
      margin-top: 24px;
    }
  }
}

.PCIconLink {
  margin-top: 20px;
  display: flex;
  column-gap: 20px;
  img {
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
}
